import * as bootstrap from 'bootstrap'
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

document.addEventListener("DOMContentLoaded", function (event) {
    window.onscroll = function () { myFunction() };
    function myFunction() {
        if (window.pageYOffset > 0) {
            document.body.classList.add("sticky")
        } else {
            document.body.classList.remove("sticky");
        }
    }
});
